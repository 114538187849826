.body-main
    min-height: 100vh;
    position: relative;
    margin: 0;
    padding-bottom: 100px;
    box-sizing: border-box;

.body-left-nav-extended-enabled
    margin-top: 60px;
    margin-left: 204px;
    padding-top: 0px;

.body-left-nav-collapsed-enabled
    margin-top: 60px;
    margin-left: 80px;
    padding-top: 0px;
